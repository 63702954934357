.App {
  background: rgb(230, 230, 214);
  height: 100%;
  color: rgb(53, 53, 51);
}

.navLinks {
  top: 0 auto;
  text-align: center;
  font-size: 2em;
  padding-top: 1.4em;
}

.links {
  text-decoration: none;
  color: black;
  margin: 3em;
}

.links:hover {
  font-weight: 500;
}

.homeLink {
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

#logoImg {
  width: 33em;
  display: flex;
  filter: invert(18%) sepia(98%) saturate(7479%) hue-rotate(359deg)
    brightness(95%) contrast(112%);
  /* https://codepen.io/sosuke/pen/Pjoqqp  site to change color */
}

.pageTitle {
  padding-top: 2em;
  font-size: 3em;
  text-align: center;
}

.header-title {
  text-decoration: underline;
  text-decoration-color: red;
  font-size: 1.5em;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.header-text {
  text-align: left;
  padding: 1em 4em;
  font-weight: 400;
  font-size: 0.7em;
}

.about-text {
  text-align: left;
  padding: 1em 4em;
  font-weight: 400;
  font-size: 0.7em;
}

.beforeAfter {
  background: rgb(214, 214, 202);
  margin: 0.5em;
  display: inline-flex;
  text-align: center;
  padding-bottom: 0.6em;
}

.beforeAfterHor {
  background: rgb(214, 214, 202);
  margin: 0.5em;
  display: inline-flex;
  text-align: center;
  padding-bottom: 0.6em;
}

.homeImg {
  height: 70vh;
  padding: 1em;
}

.homeImgHor {
  height: 50vh;
  padding: 1em;
}

h6 {
  margin: 0 auto;
}

.footer {
  margin-top: 5em;
  background: rgb(117, 117, 108);
  height: 50vh;
}

.footerTitle {
  text-align: center;
  margin: auto;
  padding-top: 16vh;
  color: whitesmoke;
  font-size: 2.8em;
  font-family: monospace;
}

.footerText {
  text-align: center;
  margin: auto;
  /* padding-top: 20vh; */
  color: whitesmoke;
  font-size: 2em;
  font-family: monospace;
}

.contactBox {
  grid-column-start: 2;
  grid-column-end: 3;
  align-self: center;
  color: whitesmoke;
  padding: 1em;
  margin: 1em;
  border-radius: 10px;
  background: rgb(170, 170, 160);
  box-shadow: 1px 1px black;
}

.formInput {
  margin: 0.5em;
}

.textArea {
  margin-top: 1em;
  width: 89%;
}

.contactTitle {
  color: whitesmoke;
  font-weight: 400;
  margin-bottom: 1em;
  font-size: 2em;
}

.submitButton {
  margin-top: 0.2em;
  color: whitesmoke;
  border-radius: 3px;
  background: rgb(54, 60, 65);
  padding: 0.4em 1em;
}

@media all and (max-width: 1200px) {
  .navLinks {
    display: none;
  }

  .links {
    display: none;
  }
}

@media all and (max-width: 930px) {
  .beforeAfter {
    padding: 0;
    background: inherit;
    margin: 0.1em;
    text-decoration: none;
  }

  .beforeAfterHor {
    display: block;
    padding: 0;
    background: inherit;
    margin: 0.1em;
    text-decoration: none;
  }

  .homeImg {
    display: inline-flex;
    text-align: center;
    height: 70vw;
    padding: 0;
    margin: 0.5em;
  }

  .homeImgHor {
    /* display: inline-block; */
    text-align: center;
    height: 60vw;
    padding: 0;
    margin: 0.5em;
  }
}

@media all and (max-width: 830px) {
  #logoImg {
    width: 22em;
  }

  .header-title {
    font-size: 0.9em;
    margin: auto 20px;
  }

  .header-text {
    text-align: center;
    padding: 1em 0.9em;
    font-weight: 400;
    font-size: 0.5em;
  }

  .about-text {
    text-align: center;
    padding: 1em 0.9em;
    font-weight: 400;
    font-size: 0.5em;
  }

  h6 {
    font-size: 0.6em;
  }

  .contactBox {
    margin: 0 4em;
  }

  .footerText {
    padding: 0 0.5em;
    font-size: 1.2em;
  }
}
